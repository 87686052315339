import React from "react";
import './NameCli.css'

export function NameCli() {
    return (
        <div className="vertical-center col">
            <div className="row blast-root">
                <h1>
                    Hi, I am Miguel
                </h1>
            </div>
        </div>
    );
}
